.svg-cc {
  position: relative;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  width: 150px;
  }

  .square-cc {
  stroke-dasharray:50;
  stroke-dashoffset:-50;
  animation: draw-square-cc 2s infinite;
  }

  @keyframes draw-square-cc {
  from {
      stroke-dashoffset: -100;
  }
  to {
      stroke-dashoffset: 100;
  }
  }