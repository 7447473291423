.centerTitle {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.ButtonMasNotas{
  color: #DA0067 !important;
  background-color: white !important;
  box-shadow: none !important;
  text-decoration: underline !important;
  margin-bottom: 2% !important;
}

.disclaimerBlog{
  font-size: 12px;
  text-align: justify;
}