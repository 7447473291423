.investors-credicuotas-bind-logo {
  width: 300px;
}

.investors-container {
  padding-left: 100px;
  padding-right: 100px;
}

.investors-container-extended {
  padding-left: 300px;
  padding-right: 300px;
}

.investors-about-data-box {
  border-radius: 10px;
  background-color: #F2F2F2;
  margin: 10px;
  padding-left: 25px;
  padding-right: 25px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  min-height: 200px;
}

.investors-about-title {
  color: #DA0067;
  font-size: 25px !important;
  font-weight: 700 !important;
  font-family: 'Poppins' !important;
}

.investors-about-description {
  color: #DA0067;
  font-size: 20px !important;
  font-family: 'Poppins' !important;
  text-align: left;
}

.investors-contact-us {
  font-size: 20px !important;
  font-family: 'Poppins' !important;
  text-align: center;
}

.investors-financial-report {
  background-color: #F9CFD4;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 100px;
  padding-right: 50px;
  padding-top: 20px;
  padding-bottom: 20px;
}

.investors-financial-report-title {
  color: #262626 !important;
  font-size: 45px !important;
  font-weight: 700 !important;
  font-family: 'Poppins' !important;
  text-align: left;
}

.investors-financial-report-img {
  width: 400px;
}

.investors-press-container {
  padding-left: 100px;
  padding-right: 100px;
}

@media only screen and (max-width: 1024px) {
  .investors-credicuotas-bind-logo {
    width: 200px;
  }

  .investors-container {
    padding-left: 40px;
    padding-right: 40px;
  }

  .investors-container-extended {
    padding-left: 40px;
    padding-right: 40px;
  }

  .investors-about-data-box {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .investors-about-description {
    color: #DA0067;
    font-size: 20px !important;
    font-family: 'Poppins' !important;
    text-align: center;
  }

  .investors-financial-report {
    background-color: #F9CFD4;
    display: flex;
    flex-direction: column;
    padding-left: 0px;
    padding-right: 0px;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .investors-financial-report-title {
    font-size: 35px !important;
  }

  .investors-financial-report-img {
    width: 300px;
  }

  .investors-press-container {
    padding-left: 10px;
    padding-right: 10px;
  }
}