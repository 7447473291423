.history-card-container {
  text-align: left;
  padding-left: 40px;
  padding-right: 40px;
  padding-top: 15px;
  padding-bottom: 15px;
  border-radius: 20px !important;
  min-height: 270px;
}

.history-card-title {
  font-size: 25px !important;
  font-weight: 700 !important;
  font-family: 'Poppins' !important;
  color: #383838 !important;
}

.history-card-img-box {
  margin-top: 20px;
  display: flex;
  align-items: center;
}

.history-card-img1 {
  max-height: 50px;
  max-width: 100px;
}

.history-card-img2 {
  max-height: 50px;
  max-width: 100px;
  margin-left: 20px;
}