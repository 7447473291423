.payServicesBody {
  overflow-x: hidden;
}

.mainBoxPayServices {
  height: 600px;
  background-color: #F2F2F2;
  background-image: url("../images/payServices/backgroundMainServices.svg");
}

.gridMainBoxPayServices {
  padding-top: 100px;
}

.imgLadyTablet {
  width: 300px;
}

.titlePayServices {
  color: #383838 !important;
  font-size: 50px !important;
}

.subtitlePayServices {
  color: #383838 !important;
  font-weight: 700 !important;
  font-size: 30px !important;
  font-family: 'Open Sans' !important;
}

.payServicesBoxWhatToPay {
  height: 500px;
}

.payServicesBoxWhatToPayTitle {
  padding-top: 40px;
}

.payServicesSubtitleWhatToPay {
  font-family: 'Open Sans' !important;
  color: '#383838';
}

.payServicesPayTickets {
  padding-top: 30px;
  padding-bottom: 30px;
  background-color: #F9CFD4;
}

.payServicesRecharge {
  padding-top: 30px;
  padding-bottom: 30px;
  background-color: #FFDFA5;
}

.imgHand {
  width: 450px;
}

.imgRecharge {
  width: 320px;
}

.payServicesHowWorks {
  height: 580px;
  padding-top: 40px;
}

.payServicesFlexBoxCircle {
  display: flex;
  justify-content: center;
  align-items: center;
}

.payServicesGridContainerBoxCircle {
  margin-top: 20px;
}

.payServicesBoxCircle {
  background-color: #F2F2F2;
  height: 170px;
  width: 170px;
  border-radius: 250px;
}

.imgBoxCircle {
  width: 150px;
}

.imgBoxCircleFace {
  width: 200px;
}

.payServicesTypographyBoxCircle {
  width: 190px;
  color: #383838;
  font-weight: 500;
}

.payServicesTypographyBoxCircleNumber {
  color: #262626;
  font-weight: 600;
}

.payServicesBenefitsTitle {
  font-weight: 900 !important;
  color: #262626;
}

.payServicesBoxHandImage {
  background-image: url("../images/payServices/patronMagenta.svg");
  background-repeat: no-repeat;
  background-size: cover;
  height: 400px;
  width: 700px;
}

.payServicesBoxRechargeBackground {
  background-image: url("../images/payServices/patronAmarriloanaranjado.svg");
  background-repeat: no-repeat;
  background-size: cover;
  height: 400px;
  width: 700px;
}

.payServicesWhyCC {
  background-color: #F2F2F2;
  height: 400px;
  text-align: left;
  margin-top: -25px;
}

.payServicesWhyBox {
  width: 100%;
  padding-top: 50px;
}

.payServicesWhyBoxInside {
  display: flex;
  align-items: center;
}

.payServicesWhyTypography {
  color: #383838;
  font-weight: 600 !important;
  padding-left: 20px;
}

.payServicesBoxVibe {
  width: 100%;
  height: 100%;
  position: relative;
}

.payServicesBoxVibeTitle {
  position: absolute;
  top: 65%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 70%;
  height: 80px;
  z-index: 997 !important;
  padding-right: 120px;
  padding-left: 120px;
}

.payServicesBoxVibeBoxTypography {
  font-weight: 600 !important;
  color: #383838;
}

.imgGreyVibe {
  width: 100%;
  height: 350px;
}

.payServicesBoxVibeBackground {
  position: absolute;
  bottom: -1%;
  width: 100%;
  height: 100px;
  background-color: #F2F2F2;
}


@media only screen and (min-width: 1201px) {
  .titlePayServices {
    color: #383838 !important;
    font-size: 60px !important;
    line-height: 75px !important;
  }

  .subtitlePayServices {
    color: #383838 !important;
    font-weight: 700 !important;
    font-size: 30px !important;
    font-family: 'Open Sans' !important;
  }

  .payServicesGridItemPayments {
    margin-left: 150px !important;
    margin-top: 20px !important;
  }

  .buttonPayNow {
    margin-top: 60px;
  }

  .payServicesGridItemRecharge {
    padding-left: 50px;
  }
}


@media only screen and (max-width: 1201px) {
  .mainBoxPayServices {
    height: 600px;
  }

  .imgHand {
    width: 280px;
  }

  .payServicesPayTickets {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .payServicesGridItemPayments {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    padding-right: 15px;
    padding-left: 15px;
    padding-top: 10px;
  }

  .payServicesBoxHandImage {
    position: static;
    height: 250px;
    width: 400px;
  }

  .buttonPayNow {
    margin-top: 30px;
    width: 100%;
  }

  .payServicesRecharge {
    padding-top: 30px;
    padding-bottom: 30px;
  }
  
  .imgRecharge {
    width: 220px;
  }

  .payServicesGridItemRecharge {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    text-align: center;
    padding-top: -2px;
    padding-right: 15px;
    padding-left: 15px;
  }

  .payServicesBoxRechargeBackground {
    height: 250px;
    width: 400px;
    margin-top: 20px;
  }

  .payServicesBoxVibeTitle {
    width: 100%;
    padding-right: 10px;
    padding-left: 10px;
  }

  .payServicesBoxVibeBackground {
    height: 100px;
    z-index: -997 !important;
  }

  .payServicesWhyCC {
    margin-top: -80px;
    z-index: -998 !important;
  }

}


@media only screen and (max-width: 900px) {

  .payServicesHowWorks {
    height: 1050px;
  }

  .payServicesGridContainerBoxCircle {
    margin-top: 30px;
  }

  .payServicesWhyBoxInside {
    padding-left: 20px;
    padding-right: 20px;
  }

  .payServicesBoxVibeBackground {
    height: 150px;
  }

  .payServicesBoxVibe {
    margin-top: -90px;
  }

  .payServicesBoxWhatToPayTitle {
    padding-left: 30px;
    padding-right: 30px;
  }

  .payServicesBoxWhatToPay {
    height: 410px;
  }

}

@media only screen and (min-width: 901px) {

  .payServicesFirstGridContainerBoxCircle {
    margin-top: 20px;
  }

  .payServicesWhyBoxInside {
    padding-left: 25%;
  }

  .payServicesBoxVibe {
    margin-top: -40px;
  }

}