.avatar-card-img {
  width: 480px !important;
  height: 325px !important;
}

.avatar-card-box {
  display: flex;
  align-items: center;
  justify-content: center;
}

.avatar-card-title {
  font-size: 25px !important;
  font-weight: 700 !important;
  font-family: 'Poppins' !important;
  color: #383838;
}

.avatar-card-subtitle {
  font-size: 20px !important;
  font-style: italic;
  font-family: 'Poppins' !important;
  color: #383838;
}

@media only screen and (max-width: 1024px) {
  .avatar-card-img {
    width: 380px !important;
    height: 257px !important;
  }

  .avatar-card-box {
    display: flex;
    flex-direction: column;
  }
}