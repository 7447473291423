.iconConsumer {
  width: 200px;
}

.firstIconConsumer {
  width: 200px;
}

.gridIconsConsumer {
  display: flex;
  flex-wrap: wrap;
  align-content: flex-end;
  justify-content: center;
  align-items: center;
  padding-top: 40px;
  padding-bottom: 40px;
}

.boxTypographyConsumer {
  width: 100%;
}

.boxForWhatConsumer {
  height: 260px;
  border-radius: 42px;
  width: 90%;
  padding: 10px;
}

.boxLegalNotifications {
  background-color: #FFDFA5;
  height: 450px;
}

.imgNotifications {
  width: 480px;
}

.marginNotifications {
  margin-top: 20px;
}

@media only screen and (max-width: 900px) {
  .iconConsumer {
  width: 160px;
  }

  .firstIconConsumer {
    width: 155px;
  }
}

@media only screen and (max-width: 1200px) {
  .boxForWhatConsumer {
    height: 250px;
    border-radius: 42px;
    width: 280px;
  }

  .marginUp {
    padding-top: 30px;
  }

  .widthBoxConsumer {
    width: 80%;
  }

  .boxLegalNotifications {
    background-color: #FFDFA5;
    height: 790px;
  }

  .imgNotifications {
    width: 300px;
  }
  
  .boxLegalNotifications {
    padding: 10px;
  }

  .widthLess {
    width: 80%;
  }
}