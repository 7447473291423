.imgTasas {
  width: 400px;
  height: 330px;
  padding-top: 2%;
}

@media only screen and (max-width: 900px) {  
  .imgTasas {
    width: 249px;
    height: 207px;
    margin-top: 15%;
  }
}