.benefit-card-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 160px;
}

.benefit-card-img {
  width: 80px;
}

.benefit-card-description {
  margin-top: 10px !important;
  font-family: 'Poppins' !important;
  font-weight: 400 !important;
  font-size: 25px !important;
  line-height: 37.5px !important;
  color: #383838;
  text-align: left;
}

@media only screen and (max-width: 1024px) {
  .benefit-card-container {
    height: 120px;
  }

  .benefit-card-img {
    width: 41px;
  }

  .benefit-card-description {
    font-weight: 500 !important;
    font-size: 14px !important;
    line-height: 21px !important;
  }
}