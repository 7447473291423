.bannerSeac{
    padding: 4% 0;
}

.boxTitle {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.seacTitle{
    font-family: sans-serif;
    padding-bottom: 2%;
    font-weight: 900 !important;
    color: #383838;
    font-size: 2.5rem !important;
    margin: 0 auto;
}

.seacSubtitle{
    font-weight: 600 !important;
    padding-bottom: 3%;
    font-size: 1.2rem;
}

.ButtonNaranja{
    font-family: sans-serif;
    align-items: center;
    opacity: 1;
    border-radius: 35px !important;
    height: 3rem;
    line-height: 4.6rem;
    text-align: center;
    font-weight: 600;
    cursor: pointer;
    width: 30%;
    box-shadow: rgb(0 0 0 / 57%) 0px 5px 40px -10px;
    background-color: rgb(233, 136, 50) !important;
    color: rgb(255, 255, 255);
    border: none;
}

.ButtonNaranja:hover{
    background-color: white !important;
    color:  rgb(233, 136, 50);
    border: solid;
    border-color:  rgb(233, 136, 50);
}

.stepFondo{
    background-color: rgb(246, 246, 246);
    padding-bottom: 2%;
    margin: 0 auto;
}

.boxStepSeac{
    padding-bottom: 3%;
    text-align: center;
    width: 100%;
    margin: 0 auto;
    padding-top: 1%;
}

.stepTitle{
    text-align: center;
    font-size: 1.9rem;
}

.boxSeac{
    background: 0% 0% no-repeat padding-box padding-box rgb(255, 255, 255);
    box-shadow: rgb(0 0 0 / 16%) 0px 3px 6px;
    opacity: 1;
    width: 460px;
    height: 300px;
    text-align: center;
    margin-top: 1rem;
    border: 3px solid rgb(255, 252, 252);
    padding: 4rem 3rem 1rem;
}

.seacIcons1{
    width: 3.5rem;
    height: 7rem;
    margin: 0px;
}

.seacIcons2{
    width: 4rem;
    height: 7rem;
    margin: 0px;
}

.seacIcons3{
    width: 5rem;
    height: 7rem;
    margin: 0px;
}

.textIconsSeac{
    width: 100%;
    font-weight: 400;
}

.seacTasasFooter{
    padding: 4% 2%;
    margin-top: 2%;
    text-align: center;
}

.seacTasasText{
    font-size: 10pt;
    padding-bottom: 1%;
    text-align: center;
    font-weight: 300;
}

.seacValores{
    font-size: 10pt;
}

@media only screen and (max-width: 900px){

    .seacTitle{
        font-size: 1.5rem !important;
    }

    .seacSubtitle{
        font-size: 1rem;
    }
    
    .ButtonNaranja{
        width: 80%;
    }

    .stepTitle{
        font-size: 1.2rem;
    }

    .stepText{
        width: 90%;
        margin: 0 auto;
        padding-top: 1%;
    }
}

@media only screen and (max-width: 576px){
    .seacTasasText{
        font-size: 8pt;
    }

    .seacValores{
        font-size: 8pt;
    }
}