.mainBox {
  color: #D70067;
  text-align: left;
  width: 100%;
  height: 420px;
  background-position: center;
}

.mainBoxNoSubtitle {
  color: #D70067;
  text-align: left;
  width: 100%;
  height: 320px;
  background-position: center;
}

.title {
  transform: translate(0,  1em);
  margin-left: 1.45em;
}

.boxSubtitle {
  width: 100%;
  margin-top: 40px;
  height: 100px;
}

.gridBannerTitle {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.imgIconBanner {
  height: 380px;
  width: 380px;
}

.subTitle {
  margin-left: 1.70em;
  margin-top: 180px;
  color: white;
}

.boxTitlesBanner {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  text-align: center;
  padding: 15px;
}

@media only screen and (max-width: 899.99px) {
  .boxSubtitle {
    width: 320px;
    height: 300px;
  }

  .subTitle {
    margin-left: 4.1em;
    margin-top: 250px;
  }
}