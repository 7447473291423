.breadcrumbs {
    font-size: 1.1rem;
  }
  
  .breadcrumbs .MuiLink-root {
    font-weight: 500;
    color: #000;
    text-decoration: none;
  }
  
  .breadcrumbs .MuiLink-root:hover {
    text-decoration: underline;
  }
  
  .breadcrumbs .MuiTypography-root {
    font-weight: 500;
    color: #000;
  }
  