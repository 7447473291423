.hub-renewer-banner {
  background-color: #F2F2F2;
  background-image: url("../images/payServices/backgroundMainServices.svg");
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 30px 20px 50px;
}

.hub-renewer-banner-title {
  font-family: 'Poppins' !important;
  font-weight: 700 !important;
  font-size: 60px !important;
  color: #383838;
  text-align: left;
}

.hub-renewer-banner-description {
  font-family: 'Open Sans' !important;
  font-weight: 400 !important;
  font-size: 30px !important;
  color: #383838;
  text-align: left;
}

.hub-renewer-section-title {
  margin-top: 80px !important;
  font-family: 'Poppins' !important;
  font-weight: 700 !important;
  font-size: 64px !important;
  line-height: 74px !important;
  text-align: center;
  color: #383838;
}

.hub-renewer-final-banner-container {
  background: url('../images/hubRenewers/background-final-banner.png'), #FBE6E9;
  height: 297px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 81px;
}

.hub-renewer-final-banner-title {
  font-family: 'Poppins' !important;
  font-weight: 700 !important;
  font-style: italic !important;
  font-size: 45px !important;
  line-height: 67.5px !important;
  color: #383838;
}

.hub-renewer-final-banner-description {
  margin-top: 10px !important;
  font-family: 'Poppins' !important;
  font-weight: 400 !important;
  font-style: italic !important;
  font-size: 30px !important;
  line-height: 20px !important;
  color: #383838;
}

@media only screen and (max-width: 1024px) {
  .hub-renewer-banner {
    padding: 20px 30px 20px 30px;
  }

  .hub-renewer-banner-title {
    font-size: 38px !important;
    line-height: 50px !important;
    text-align: center;
    padding: 0px;
  }

  .hub-renewer-banner-description {
    margin-top: 10px !important;
    font-size: 20px !important;
    text-align: center;
  }

  .hub-renewer-section-title {
    margin-top: 40px !important;
    font-size: 30px !important;
    line-height: 40px !important;
  }

  .hub-renewer-final-banner-container {
    background: url('../images/hubRenewers/background-final-banner-mobile.png'), #FBE6E9;
    height: 134px;
    border-radius: 14px;
    padding: 20px 30px;
  }

  .hub-renewer-final-banner-title {
    font-size: 19px !important;
    line-height: 25px !important;
  }
  
  .hub-renewer-final-banner-description {
    font-size: 14px !important;
  }
}