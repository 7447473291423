.borderBox{
    height: 272px;
    max-height: 302px;
    width: 349px;
    max-width: 380px;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)}
.marginText{
    margin-left: 27px;
    margin-right: 27px;
}