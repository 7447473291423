@import url('https://fonts.googleapis.com/css2?family=Coming+Soon&family=Open+Sans:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans&family=Poppins:wght@300;400;500;600;700&display=swap');

.ocultar{
  display: none;
}

.ButtonMagenta {
  background-color: #DA0067 !important;
  border-radius: 35px !important;
  margin: 0;
  padding: 5px 15px;
  color: #FFFFFF;
  text-transform: none;
  font-family: 'Open Sans' !important;
  font-size: 14px;
  font-weight: 800;
}

.ButtonBlog{
  font-family: sans-serif;
  align-items: center;
  opacity: 1;
  border-radius: 35px !important;
  height: 2rem;
  line-height: 2rem;
  text-align: center;
  font-weight: 600;
  cursor: pointer;
  width: 40%;
  box-shadow: rgb(0 0 0 / 57%) 0px 5px 40px -10px;
  background-color: white !important;
  color: #383838 !important;
  border: 2px solid !important;
  border-color: #DA0067 !important;
}

.buttonMoreInfo {
  width: 220px;
}

.ButtonYellow {
  background-color: #FFA500 !important;
}

.content img {
  display: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Poppins' !important;
}

.content {
  padding: 20px;
  text-align: left;
}

p {
  font-family: 'Open Sans' !important;
}

span {
  font-family: 'Open Sans';
}

.marginBottomQuestions {
  margin-bottom: 2%;
}

.marginBottomLastQuestion {
  margin-bottom: 60px;
}

.paddingBottomLastQuestion {
  padding-bottom: 60px;
}

.titleBanner{
  font-size: 3rem !important;
}

.lettersWhite {
  color: white;
  width: 79%;
}

.lettersWhiteOnly {
  color: white;
}

.lettersMagenta {
  color: #DA0067 !important;
}

.lettersMagentaNew {
  color: #DA0067 !important;
}

.lettersMagentaVersionThree {
  color: #D50067 !important;
}

.lettersGrey {
  color: #383838 !important;
}

.lettersVersionTwo {
  color: #585858 !important;
}

.lettersGreyParapgraph {
  color: #383838 !important;
}

.backgroundMagenta {
  background-color: #DF1365 !important;
}

.backgroundWhite {
  background-color: white !important;
}

.backgroundYellow {
  background-color: #FAB719 !important;
}

.spaceMedium {
  margin-top: 40px;
}

.textBannerHome {
  padding-top: 3%;
}

.textLeft {
  text-align: left;
}

.boxBannerHome {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.boxCalculatorHome {
  background-image: url("../images/home/bannerHome.svg");
  width: 100%;
  height: 570px;
  background-position: center;
  /* background-repeat: no-repeat;
  background-size: cover; */
}

.marginTitleHome {
  padding-top: 90px;
}

.titleBannerHome {
  font-weight: 900;
}

.boxTitleHome {
  width: 600px;
  display: flex;
  direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.boxSubtitleHome {
  width: 450px;
  margin-top: 15px;
}

.gridHome {
  text-align: left;
}

.bannerHomePhysicalCommerce {
  text-align: left;
  width: 100%;
  padding-top: 5%;
  padding-right: 5%;
  padding-left: 5%;
}

.circleBox {
  background-color: #FFDFA5;
  border-radius: 300px;
  width: 276px;
}

.boxForWhat {
  height: 260px;
  border-radius: 42px;
  padding: 10px;
}

.boxWhyUs {
  background-image: url("../images/home/fondo-onda-signo-01.svg");
  height: 640px;
  width: auto;
  background-repeat: no-repeat;
  background-size: cover;
}

.boxBannerBnpl {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 95%;
  padding-top: 7%;
}

.boxSmallTypography {
  width: 30%;
  margin-top: 10px;
}

.boxMediumTypography {
  width: 70%;
  text-align: center;
}

.boxHomeBnpl {
  width: 80%;
}

.boxHomeWc {
  width: 90%;
}

.boxTextBnpl {
  width: 90%;
  text-align: left;
  margin-top: 2%;
}

.boxBannerButtonBnpl {
  display: flex;
  align-items: center;
  justify-content: center;
}

.flexCenter {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.flexCenterUp {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
}

.flexStartVertical {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.flexStartVerticalCalculator {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.textTakeCredit {
  font-weight: 700;
  font-size: 34px;
  line-height: 51px;
}

.flexWhatNeed {
  display: flex;
  align-items: center;
}

.flexCircleHome {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.flexLastCircleHome {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  align-items: center;
}

.boxBnpl {
  background-color: #FFDFA5;
  height: 420px;
}

.boxWc {
  background-color: #F9CFD4;
  height: 420px;
}

.boxPayServicesHome {
  background-color: #FFDFA5;
  height: 420px;
  padding-top: 20px;
}

.buttonBannerHomePosition {
  position: absolute;
  bottom: 2%;
  left: 0;
  right: 0;
}

.typographyTitles {
  font-size: 64px;
  font-weight: 900;
}

.boxRequirements {
  background-color: #F3F3F3;
  height: 240px;
  text-align: left;
  border-radius: 30px;
  padding-top: 30px;
  padding: 10px;
}

.imgCheckListPaper {
  width: 360px;
}

.imgFaceBiometry {
  width: 360px;
}

.imgOkeyHand {
  width: 270px;
}

.flexImage {
  display: flex;
  justify-content: center;
}

.widthBoxTypography {
  width: 100%;
}

.imgMoto {
  width: 75%;
}

.imgTelephone {
  width: 60%;
}

.imgMonitor {
  width: 60%;
}

.imgMoney {
  width: 80%;
}

.paddingTitle {
  padding-top: 150px;
}

.boxSelectorWidth {
  width: 65%;
}

.homePayServicesBoxTitle {
  padding-top: 30px;
  padding-left: 40px;
  text-align: left;
}

.homePayServicesBoxSubTitle {
  padding-top: 20px;
}

.landing-banner {
  margin-top: 80px;
  background-image: url("../images/banners/landing-banner-desktop.jpg");
  background-position: right center;
  background-size: cover;
  background-color: #f2025d;
  position: relative;
}

.landing-banner-div {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 40px;
  padding-left: 60px;
}

.landing-banner h1 {
  font-family: 'Poppins' !important;
  font-weight: 700;
  font-size: 78px;
  color: white;
  text-align: left;
}

.landing-banner p {
  font-family: 'Open Sans' !important;
  font-weight: 400;
  font-size: 32px;
  color: white;
  text-align: left;
}

.landing-banner button {
  background-color: white !important;
  color: #D70067 ;
  font-weight: 700;
  width: 304px;
  height: 50px;
  font-size: 18px !important;
  line-height: 24px !important;
  border-radius: 30px;
  font-family: 'Open Sans' !important;
}

.landing-banner img {
  max-width: 100%;
}

.body h2 {
  font-size: 44px;
  font-weight: 700;
  line-height: 74px;
  color: #424242;
}

.landing-how-to-steps {
  padding: 40px;
}

.landing-how-to-steps p {
  color:#F2025D;
  font-size: 28px !important;
}

.landing-requirements {
  background-color: #F9F9F9;
  padding: 40px;
}

.landing-requirements h2 {
  font-size: 40px;
  color: #F2025D;
  text-align: left;
  font-weight: 700;
  line-height: 60px;
}

.landing-requirements p {
  font-size: 20px;
  color: #585858;
  font-weight: 600;
}

.landing-models p {
  font-size: 24px;
  color: #424242;
  font-weight: 700;
  padding: 20px;
}

.landing-choose-us {
  background-image: url("../../../public/images/background/fondo-onda-signo.svg");
  background-size: cover;
  padding-top: 260px;
  padding-left: 140px;
  padding-right: 140px;
  padding-bottom: 60px;
}

.landing-choose-us h2 {
  font-family: 'Poppins';
  font-weight: 700;
  font-size: 56px;
  line-height: 74px;
  color: white;
}

.landing-choose-us h4 {
  text-align: left;
  font-family: 'Poppins';
  font-weight: 700;
  font-size: 40px;
  line-height: 60px;
  color: white;
}

.landing-choose-us p {
  text-align: left;
  font-family: 'Open Sans' !important;
  font-weight: 400;
  font-size: 32px;
  line-height: 43px;
  color: white;
}

.landing-choose-us-message {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 40px;
  padding-right: 40px;
  gap: 40px;
  margin-top: 30px;
}

.motorcycle-faq {
  margin-left: 40px;
  margin-right: 40px;
}

.motorcycle-faq h3 {
  font-family: 'Open Sans' !important;
  font-size: 32px;
  line-height: 43px;
}

.motorcycle-faq p {
  font-family: 'Open Sans' !important;
  font-size: 20px;
  text-align: left;
}

.landing-faq-more {
  font-family: 'Poppins';
  font-size: 33px !important;
  line-height: 43px !important;
  color: #DA0067;
}

.landing-faq-more p {
  font-weight: 400;
}

.landing-faq-more a {
  font-weight: 700;
  color: #DA0067 !important;
}

.landing-footer {
  padding-left: 40px;
  padding-right: 40px;
}

.landing-footer p {
  color: #585858;
}

@media only screen and (max-width: 901px) {
  .boxCalculatorHome {
    height: 710px !important;
  }

  .circleBox {
    background-color: #FFDFA5;
    border-radius: 300px;
    width: 175px;
    height: 175px;
  }

  .imgCheckListPaper {
    width: 260px;
  }

  .imgFaceBiometry {
    width: 230px;
  }

  .imgOkeyHand {
    width: 270px;
  }

  .whyBox {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 8%;
    height: 22px;
  }

  .whyBoxTopOne {
    padding-top: 120px;
  }

  .whyBoxTop {
    padding-top: 210px;
  }

  .paddingTitle {
    padding-top: 65px;
  }

  .landing-banner {
    background-image: url("../images/banners/landing-banner-mobile.jpg");
  }
  
  .landing-banner-div {
    gap: 20px;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 40px;
    padding-bottom: 40px;
  }
  
  .landing-banner h1 {
    font-size: 32px;
  }
  
  .landing-banner p {
    font-size: 20px;
  }
  
  .landing-banner button {
    width: 160px;
    height: 40px;
    font-size: 14px !important;
  }
  
  .landing-banner img {
    width: 50%;
    position: absolute;
    right: 0px;
    bottom: 0px;
  }
  
  .body h2 {
    font-size: 24px;
    line-height: 36px;
    text-align: center;
  }
  
  .landing-how-to-steps {
    padding: 40px;
  }
  
  .landing-how-to-steps p {
    font-size: 16px !important;
  }
  
  .landing-requirements h2 {
    font-size: 20px;
    line-height: 30px;
    text-align: center;
  }
  
  .landing-requirements p {
    font-size: 16px;
    font-weight: 400;
  }
  
  .landing-models p {
    font-size: 14px;
    font-weight: 700;
    padding: 10px;
  }
  
  .landing-choose-us {
    background-image: url("../../../public/images/background/fondo-onda-signo.svg");
    background-position: top center;
    background-size: cover;
    padding-top: 80px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 50px;
  }
  
  .landing-choose-us h2 {
    font-size: 24px;
    line-height: 36px;
    text-align: center;
  }
  
  .landing-choose-us h4 {
    font-size: 16px;
    line-height: 21px;
  }
  
  .landing-choose-us p {
    font-size: 16px;
    line-height: 21px;
  }

  .landing-choose-us-message {
    gap: 20px;
    margin-top: 20px;
    padding-left: 0px;
    padding-right: 0px;
  }
  
  .landing-faq {
    margin-bottom: 0px !important;
    margin-left: 20px;
    margin-right: 20px;
  }
  
  .landing-faq h3 {
    font-size: 14px;
    line-height: 19px;
    text-align: left;
  }
  
  .landing-faq p {
    font-size: 12px;
    text-align: left;
  }
  
  .landing-faq-more {
    font-size: 16px !important;
    line-height: 24px !important;
  }
  
  .landing-footer {
    padding-left: 40px;
    padding-right: 40px;
  }
  
  .landing-footer p {
    font-size: 12px;
    color: #585858;
  }
}

@media only screen and (max-width: 1199px) {
  .secondBoxesImages {
    margin-top: 20px;
  }
}

@media only screen and (max-width: 1201px) {
  .boxCalculatorHome {
    background-image: url("../images/home/bannerHome.svg");
    height: 870px;
    width: auto;
    background-size: auto 120%;
    background-repeat: no-repeat;
  }

  .gridHome {
    text-align: center;
    display: flex;
    justify-content: center;
  }

  .boxSubtitleHome {
    width: 100%;
  }

  .boxSmallTypography {
    width: 100%;
    margin-top: 8px;
    text-align: center;
  }

  .spaceMobile {
    margin-top: 25px;
  }

  .marginTitleHome {
    padding-top: 40px;
  }

  .flexCircleHome {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }

  .flexLastCircleHome {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }

  .boxRequirements {
    background-color: #F3F3F3;
    height: 760px;
    text-align: left;
    border-radius: 30px;
    padding: 30px;
  }

  .flexImage {
    display: flex;
    justify-content: center;
  }

  .boxBnpl {
    background-color: #FFDFA5;
    padding: 20px;
    height: 620px;
  }

  .boxWc {
    background-color: #F9CFD4;
    padding: 20px;
    height: 620px;
  }

  .boxPayServicesHome {
    height: 620px;
  }

  .homePayServicesBoxTitle {
    text-align: center;
    padding-right: 10px;
    padding-left: 10px;
  }

  .boxForWhat {
    height: 200px;
    border-radius: 42px;
  }

  .boxSelectorWidth {
    width: 90%;
  }

  .boxWhyUs {
    height: 760px;
  }
}

@media only screen and (min-width: 1200px) {
  .bannerHome {
    width: 100%;
    height: 630px;
    position: relative;
  }

  .iconBannerHome {
    width: 490px;
    padding-top: 5%;
    padding-right: 5%;
    padding-left: 5%;
  }

  .iconBannerBnpl {
    width: 280px;
  }

  .bannerHomeWt {
    text-align: left;
    width: 95%;
    padding: 5%;
  }

  .bannerHomeImagePhysicalCommerce {
    width: 280px;
  }

  .buttonBannerHome {
    border-radius: 10px !important;
    width: 370px;
  }

  .boxTitleBnpl {
    width: 75%;
    text-align: left;
    margin-top: 3%;
    padding-left: 5%;
  }

  .bannerHomePhysicalCommerceText {
    text-align: left;
    width: 100%;
    padding-bottom: 5%;
    padding-right: 5%;
    padding-left: 5%;
  }

  .boxBannerHomePhysicalCommerce {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 12%;
    padding-bottom: 5%;
  }

  .marginCalculator {
    padding-top: 50px;
  }
}

@media only screen and (max-width: 899.99px) {
  .imgMoto {
    width: 150px;
  }

  .imgTelephone {
    width: 120px;
  }

  .imgMonitor {
    width: 115px;
  }

  .imgMoney {
    width: 150px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1200px)  {
  .imgMoto {
    width: 130px;
  }

  .imgTelephone {
    width: 100px;
  }

  .imgMonitor {
    width: 105px;
  }

  .imgMoney {
    width: 140px;
  }
}

@media only screen and (min-width: 630px) and (max-width: 900px) {
  .boxTextBnpl {
    width: 80%;
  }
}

@media only screen and (min-width: 1201px) and (max-width: 1370px) {
  .boxHomeBnpl {
    width: 100% !important;
  }

  .boxHomeWc {
    width: 100%;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1730px) {
  .bannerHome {
    height: 550px !important;
  }

  /* h1 {
    font-size: 170% !important;
  } */

  .textHome {
    font-size: medium !important;
  }

  .iconBannerBnpl {
    width: 210px !important;
  }
}

@media only screen and (max-width: 1359.9px) {
  .bannerHome {
    width: 100%;
    height: 460px;
    position: relative;
  }

  .bannerHomeWt {
    text-align: left;
    width: 95%;
    padding-top: 5%;
    padding-left: 5%;
    padding-right: 5%;
  }

  .textBannerHome {
    padding-bottom: 5%;
  }

  .iconBannerHome {
    width: 350px;
    padding-right: 5%;
    padding-left: 5%;
    padding-bottom: 8%;
  }

  .iconBannerBnpl {
    width: 200px;
  }

  .buttonBannerHome {
    border-radius: 10px !important;
    width: 70%;
  }

  .boxTitleBnpl {
    width: 75%;
    text-align: left;
    margin-top: 3%;
    padding-left: 7%;
  }

  .bannerHomePhysicalCommerceText {
    text-align: left;
    width: 100%;
    padding-right: 5%;
    padding-left: 5%;
  }

  .boxBannerHomePhysicalCommerce {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 12%;
    padding-bottom: 2%;
  }

  .bannerHomeImagePhysicalCommerce {
    width: 220px !important;
  }

}

@media only screen and (max-width: 600px) {
  .titleMobile {
    font-size: 15pt;
  }

  .css-1sd8qvx-MuiTypography-root{
    font-size: 2.6rem !important;
  }
}

@media only screen and (min-width: 900px) {
  .gridSpaces {
    margin-top: 8%;
  }

  .whyBox {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 30px;
  }

  .whyBoxInside {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    margin-left: 20px;
    text-align: left;
  }

  /* h1 {
    font-size: 250%;
  } */

  .h2Home {
    text-align: left;
  }

  .h2PaddingLeftHome {
    padding-left: 3%;
  }

  .textHome {
    font-family: 'Open Sans' !important;
    font-size: larger;
    text-align: left;
  }

  .pBoxHome {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
  }

  .pBoxHomeWhy {
    padding-left: 29.5%;
  }

  .pBoxHomeRequirements {
    justify-content: center;
  }

  .requirementsBox {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
}


@media only screen and (max-width: 899.99px) {
  .gridSpaces {
    margin-top: 15%;
  }
  /* h1 {
    font-size: 180%;
  } */

  .h2Home {
    font-size: 95%;
    text-align: left;
  }

  .h2PaddingLeftHome {
    padding-left: 3%;
  }

  .textHome {
    font-family: 'Open Sans' !important;
    font-size: 90%;
    text-align: left;
  }

  .pBoxHome {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-left: 10%;
    width: 80%;
  }

  .pBoxHomeRequirements {
    justify-content: center;
  }

  .requirementsBox {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 70%;
    margin-top: 4%;
  }

  .pRequirements {
    margin-top: 10px;
    margin-bottom: 30px;
  }
}

.imgCheck {
  width: 25px;
}

.imgTick {
  width: 55px;
}