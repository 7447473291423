.boxTitle {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.logoHeaderUber{
    margin-left: 20%;
}

.lineaUber{
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    text-align: center;
    margin-right: 1rem;
    margin-left: 1rem;
    border-left: 2px solid rgb(255, 255, 255);
    height: 3rem;
}

.bannerUber{
    padding: 4% 0;
}

.uberTitle{
    font-family: sans-serif;
    padding-bottom: 2%;
    font-weight: 800 !important;
    color: #383838;
    font-size: 2.4rem !important;
    margin: 0 auto;
}

.uberSubtitle{
    font-weight: 600 !important;
    padding-bottom: 3%;
    font-size: 1.2rem;
}

.ButtonNegro{
    font-family: sans-serif;
    align-items: center;
    opacity: 1;
    border-radius: 35px !important;
    height: 3rem;
    line-height: 4.6rem;
    text-align: center;
    font-weight: 600;
    cursor: pointer;
    width: 30%;
    box-shadow: rgb(0 0 0 / 57%) 0px 5px 40px -10px;
    background-color: black !important;
    color: rgb(255, 255, 255);
    border: none;
}

.ButtonNegro:hover{
    background-color: white !important;
    color: black;
    border-color: black;
}

.boxUber{
    background: 0% 0% no-repeat padding-box padding-box rgb(255, 255, 255);
    box-shadow: rgb(0 0 0 / 16%) 0px 3px 6px;
    opacity: 1;
    width: 460px;
    height: 300px;
    text-align: center;
    margin-top: 1rem;
    border: 3px solid rgb(255, 252, 252);
    padding: 4rem 3rem 1rem;
}

.uberIcons{
    width: 20%;
}

.textIconsUber{
    width: 100%;
    font-weight: 400;
}

.funcionaTitle{
    font-size: 20pt;
    text-align: center;
}

.uberInfo{
    padding-top: 4%;
}

.uberInfoOrange{
    display: flex;
    flex-direction: row;
    -webkit-box-pack: center;
    justify-content: center;
    background-color: rgb(255, 192, 67);
    background-size: 100%;
    background-repeat: no-repeat;
    -webkit-box-align: center;
    align-items: center;
}

.info{
    justify-content: flex-start;
    box-shadow: rgb(0 0 0 / 16%) 0px 3px 6px;
    opacity: 1;
}

.uberInfoGrey{
    display: flex;
    flex-direction: row;
    -webkit-box-pack: center;
    justify-content: center;
    background-color: rgb(38, 49, 58);
    background-size: 100%;
    background-repeat: no-repeat;
    -webkit-box-align: center;
    align-items: center;
    width: 100%;
}

.uberInfoText{
    display: flex;
    flex-direction: column;
    -webkit-box-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    align-items: center;
    text-align: center;
    height: 150px;
    width: 100%;
    margin-top: 2%;
}

@media only screen and (max-width: 900px){

    .uberTitle{
        font-size: 1.5rem !important;
    }

    .uberSubtitle{
        font-size: 1rem;
    }
    
    .ButtonNegro{
        width: 80%;
    }

    .boxImgUber{
        width: 90%;;
    }

    .imgUber{
        width: 90%;
    }

}

@media (min-width: 555px ) {
    .logoHeaderUber {
      width: 230px;
      height: auto;
      margin-left: 20%;
    }

    .logoHeaderUberCC{
        width: 230px;
        height: auto;
    }
  }
  
  @media (max-width: 554px ) {
    .logoHeaderUber {
      width: 100px;
      height: auto;
      margin-left: 10%;
    }

    .logoHeaderUberCC{
        width: 100px;
      height: auto;
    }
  }