.contracts-banner {
  background-color: #F2F2F2;
  background-image: url("../images/home/bannerHome.svg");
  background-size: cover;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 30px 20px 50px;
  height: 400px;
}

.contracts-banner-title {
  color: #FFF !important;
  font-family: 'Poppins' !important;
  font-size: 50px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: 70px !important;
  text-align: left;
}

.contracts-box {
  background-color: #F2F2F2;
  border-radius: 10px;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.21);
  padding: 50px 30px;
  min-height: 200px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.contracts-box-description {
  color: #000 !important;
  font-family: 'Open Sans' !important;
  font-size: 25px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: 44px !important;
  text-align: left;
}
.contracts-box-cta {
  display: flex;
  flex-direction: column !important;
  align-items: flex-end;
  justify-content: center;
}

.contracts-cta {
  background-color: #DA0067 !important;
  border-radius: 35px !important;
  margin: 0;
  color: #FFFFFF;
  font-family: 'Open Sans' !important;
  font-size: 20px !important;
  font-style: normal;
  font-weight: 700 !important;
  line-height: 28px;
}

@media only screen and (max-width: 800px) {
  .contracts-banner {
    padding: 20px;
    height: 400px;
  }

  .contracts-banner-title {
    font-size: 48px !important;
    line-height: 53.321px !important;
    text-align: center;
  }

  .contracts-box {
    padding: 20px 20px;
    min-height: 200px;
  }

  .contracts-box-description {
    font-size: 20px !important;
    line-height: 30px !important;
    text-align: center;
  }

  .contracts-box-cta {
    margin-top: 30px !important;
    flex-direction: row !important;
  }
}