.gridCarousel {
  background-color: rgb(172, 172, 172);
  padding: 3%;
}

.slick-slide > div {
  margin: 0 10px;
}
.slick-list {
  margin: 0 -10px;
}

.spaceBetween {
  margin-top: 30px;
}

.gridIcons {
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.h4Icons {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
}

.marginCarousel {
  margin-left: 70px;
}

.iconCarousel {
  width: 250px;
}

.textIcons {
  display: flex;
}

.gridContainerListCommerce {
  background-color: #FFDFA5;
  height: 745px;
}

.gridItemListCommerce {
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  justify-content: center;
}

.listScroll::-webkit-scrollbar {
  width: 14px;
  height: 14px;
}
.listScroll::-webkit-scrollbar:window-inactive {
  display: none;
}

.listScroll::-webkit-scrollbar-track-piece {
  border-radius: 2px;
  background-color: grey;
  background-clip: content-box;
  border-radius: 6px;
  border: solid 4px transparent;
}
.listScroll::-webkit-scrollbar-thumb  {
  height: 7px;
  width: 4px;
  background-color: white;
  border-radius: 6px;
  box-shadow: 0 3px 6px -2px rgba(51,0,51,0.5);
}

.listItemCommerce {
  background-color: #FFDFA5;
  border: 1px solid #383838;
  border-radius: 10px;
  max-width: 99%;
}

.boxAutocomplete {
  width: 70%;
  text-align: left;
  margin-top: 40px;
}

.gridImgShop {
  display: flex;
  align-content: flex-start;
  justify-content: center;
}

.spaceBetween {
  margin-top: 40px;
}

.imgShop {
  width: 500px;
}

.alignLeft { 
  text-align: left;
}

.boxCircleWc {
  background-color: #FFDFA5;
  height: 270px;
  width: 270px;
  border-radius: 250px;
}

.imgShopWc {
  width: 290px;
}

.imgVendorWc {
  width: 200px;
}

.imgBiciWc {
  width: 250px;
}

.boxOwnerCommerce {
  background-color: #F9CFD4;
  height: 435px;
}

.imgAffiliationCommerce {
  width: 400px;
}

.gridAffiliationCommerce {
  display: flex;
  justify-content: center;
  align-content: center;
}

.titleAFiliateCommerce {
  width: 65%;
}

.imgMotoWc {
  width: 220px;
}

.imgMonitorWc {
  width: 180px;
}

.imgFurniture {
  width: 200px
}

.imgGuitar {
  width: 190px;
}

.boxWcProducts {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
}


.widthBoxLess {
  width: 70%;
}

.widthBoxLessSmall {
  width: 45%;
}

.boxProductsWc {
  height: 260px;
  border-radius: 42px;
  padding: 10px;
}

.boxMainPartners {
  margin-top: 120px;
}

.spacePartnersCommerce {
  margin-top: 80px;
}

.flexUp {
  display: flex;
  align-content: flex-end;
  justify-content: center;
}

@media only screen and (min-width: 901px) and (max-width: 1201px) {
  .imgMotoWc {
    width: 200px;
  }

}


@media only screen and (max-width: 1199px) {
  .widthBoxLess {
    width: 100%;
  }
  
  .widthBoxLessSmall {
    width: 100%;
  }
}

@media only screen and (max-width: 1201px) {
  .iconCarousel {
    width: 160px;
  }
  
  .imgMonitorWc {
    width: 180px;
  }
  
  .imgFurniture {
    width: 200px
  }
  
  .imgGuitar {
    width: 190px;
  }

  .imgAffiliationCommerce {
    width: 250px;
  }

  .boxOwnerCommerce {
    height: 555px;
  }

  .boxAutocomplete {
    width: 70%;
    text-align: center;
    margin-top: 1px;
    padding-bottom: 3px;
  }

  .widthTitleHowWc {
    width: 80%;
  }

  .boxMainPartners {
    margin-top: 55px;
  }

  .spacePartnersCommerce {
    margin-top: 30px;
  }

  .spaceCircleWc {
    padding-top: 40px;
  }
}

@media only screen and (max-width: 900px) {
  .gridCarousel {
    padding: 10%;
  }

  .gridContainerListCommerce {
    height: 935px;
  }

  .imgShop {
    width: 200px;
  }

  .boxProductsWc {
    height: 180px;
  }

  .imgMotoWc {
    width: 140px;
  }
  
  .imgMonitorWc {
    width: 110px;
  }
  
  .imgFurniture {
    width: 130px
  }
  
  .imgGuitar {
    width: 120px;
  }

  .electroTypographyWc {
    font-size: 15px !important;
  }

  .titleAFiliateCommerce {
    width: 80%;
  }
}