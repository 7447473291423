.mainBoxError {
  background-color: #D70067;
  color: #D70067;
  text-align: left;
  width: 100%;
  height: 540px;
  background-position: center;
}

.buttonError {
  background-color: white !important;
  color: #D70067 !important;
  font-weight: 900 !important;
}

@media only screen and (max-width: 1201px) {
  .boxButtonError {
    padding-top: 25px;
  }
}

@media only screen and (min-width: 1201px) and (max-width: 1400px) {
  .errorImage {
    width: 450px;
  }
}