.press-card-container {
  text-align: left;
  padding-left: 40px;
  padding-right: 40px;
  padding-top: 15px;
  padding-bottom: 15px;
  border-radius: 20px !important;
}

.press-card-title {
  font-size: 25px !important;
  font-weight: 700 !important;
  font-family: 'Poppins' !important;
  color: #383838 !important;
}

.press-card-footer-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.press-card-footer {
  color: #979797;
  font-family: 'Poppins' !important;
}

.press-card-btn {
  color: #383838 !important;
  border-color: #DA0067 !important;
  border-radius: 20px !important;
  background-color: white !important;
  box-shadow: none !important;
}

@media only screen and (max-width: 1024px) {
  .press-card-container {
    text-align: left;
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .press-card-btn {
    font-size: 11px !important;
    padding: 0px !important;
  }
}