.boxIcons {
  border: groove;
  border-radius: 25px;
  width: 300px
}

.boxIconsHelp {
  border-radius: 25px;
  height: 250px;
  width: 260px;
  padding: 10px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
}

.boxFlexHelp {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.boxImgHelp {
  height: fit-content;
  align-content: center;
}

.imgHelpIconBills {
  width: 240px;
}

.imgHelpIconShop {
  width: 150px;
}

.imgHelpIconCommerce {
  width: 180px;
}

.gridIcons {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center !important;
}

.imgContact {
  width: 100px;
  height: 100px;
}

.questions {
  text-align: left;
}

.paddingQuestions {
  padding-top: 25px;
}

.answers {
  text-align: left;
}

.spaceAccordion {
  margin-top: 15px;
}

.boxContact {
  height: 300px;
  width: 100%;
  background-color: beige;
  margin-top: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center !important;
}

.widthBoxHelpTypography {
  width: 90%;
}

.spaceTitle {
  margin-top: 80px;
}

.boxContactHelp {
  background-color: #F3F3F3;
  width: 200px;
  height: 200px;
}

.boxChannelsHelp {
  background-color: #F3F3F3;
  height: 240px;
  text-align: left;
  border-radius: 30px;
  padding-top: 30px;
  margin-bottom: 50px;
}

.boxHelpChannelsTitle {
  text-align: left;
}

.boxBranch {
  background-color: #F9CFD4;
  height: 380px;
}

.boxBranchInfo {
  margin-top: 30px;
  text-align: left;
}

.boxParagraphBranch {
  width: 70%;
}

.imgMap {
  width: 400px;
  height: 340px;
}

@media only screen and (min-width: 1200px) and (max-width: 1500px) {
  /* .boxIconsHelp {
    border-radius: 25px;
    height: 260px;
    width: 250px;
    padding-bottom: 10px;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
  } */

  .boxFlexHelp {
    display: flex;
    justify-content: space-evenly;
  }
}

@media only screen and (max-width: 1200px) {
  .marginBoxHelp {
    margin-top: 15px;
  }

  .marginBoxContact {
    margin-top: 40px;
  }

  .boxChannelsHelp {
    height: 726px;
  }

  .imgContact {
    width: 90px;
    height: 90px;
  }

  .boxBranch {
    background-color: #F9CFD4;
    height: 670px;
  }

  .imgMap {
    width: 340px;
    height: 280px;
  }

  .boxHelpChannelsTitle {
    text-align: center;
  }

  .boxBranchInfo {
    margin-top: 30px;
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }

  .widthBoxHelpTypography {
    width: 100%;
  }

  .boxParagraphBranch {
    width: 90%;
  }

}


@media only screen and (max-width: 900px) {
  .boxContainerIcons {
    height: 445px;
  }

  .boxIcons {
    border: groove;
    border-radius: 25px;
    width: 170px;
    margin-top: 15px;
  }

  .imgIcon {
    width: 60px;
    height: 60px;
  }

  .boxContact {
    height: 530px;
  }
}