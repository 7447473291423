.sliderBoxCircle {
  background-color: #F2F2F2;
  height: 210px;
  width: 210px;
  border-radius: 250px;
}

.sliderFlexBoxCircle {
  display: flex;
  justify-content: center;
}

.payServicesWhatToPayFlexBoxCircle {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.payServicesWhatToPayFlexBoxCircleWater {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.payServicesWhatToPayFlexBoxCircleGas {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.sliderFlexBoxCircle {
  display: flex;
  justify-content: center;
}

.sliderBoxTypography {
  width: 100%;
}

.sliderBoxArrowCircle {
  background-color: #F2F2F2;
  height: 70px;
  width: 70px;
  border-radius: 250px;
}

.sliderBox {
  margin-top: 70px;
}

.sliderImgBoxCircle {
  padding-top: 20px;
  width: 70px;
}

.sliderImgBoxCircleWater {
  width: 130px;
}

.sliderImgBoxCircleInsurance {
  width: 120px;
}

.sliderImgBoxCircleRecharge {
  width: 170px;
}

.sliderBoxTypographyRecharge {
  margin-top: -30px;
}

@media only screen and (min-width: 900px) {
  .slider-button {
    background-color: #F2F2F2 !important;
  }
}


@media only screen and (max-width: 900px) {

  .sliderBox {
    margin-top: 50px;
  }
  
  .sliderBoxCircle {
    height: 120px;
    width: 120px;
  }

  .payServicesWhatToPayFlexBoxCircleWater {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-content: space-around;
  }

  .payServicesWhatToPayFlexBoxCircleGas {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }

  .sliderImgBoxCircle {
    width: 30px;
  }

  .sliderImgBoxCircleInsurance {
    width: 70px;
  }

  .sliderImgBoxCircleWater {
    width: 40px;
    padding-top: 20px;
  }

  .sliderBoxTypographyWater {
    padding-bottom: 10px;
  }

  .slider-button {
    background-color: #F2F2F2 !important;
  }

  .sliderImgBoxCircleRecharge {
    width: 90px;
    margin-top: -10px;
  }

  .sliderTypographyRecharge {
    font-size: 14px !important;
  }

  .sliderBoxTypographyRecharge {
    margin-top: -20px;
    width: 80%;
  }
  

}