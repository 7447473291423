.boxFooter {
  background: #E2E2E2;
  color: #383838;
}

.imgLogo {
  width: 280px;
  height: 40px;
}

hr {
  color: black;
  background-color: black;
  height: 2;
  margin: auto;
  width: auto;
}

.gridImages {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center !important;
}

.boxLegalInformation {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center !important;
}

.contactTitle {
  font-family: 'Poppins' !important;
  font-weight: 700 !important;
}

.contactData {
  font-family: 'Poppins' !important;
}

.imageCnv {
  width: 130px;
}

.imageRenaper {
  width: 200px;
}

.imageCid {
  width: 100px;
}

.imagePdp {
  width: 130px;
}

.imageBcra {
  width: 110px;
}

.imageDataFiscal {
  width: 60px;
}

.imageUserInformation {
  width: 140px;
}

.boxCuit {
  margin-top: 30px;
  padding-bottom: 24px;
}

.pLegalText {
  font-size: 16px !important;
}

.gridLogoCredicuotas {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;
  align-content: flex-start;
}

.gridSocialNetwork {
  margin-top: 25px;
  display: flex;
  justify-content: space-around;
}

.imgLogosSocialNetwork {
  width: 72px;
}

@media only screen and (min-width: 1200px) {
  .gridLogoCredicuotas {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-wrap: wrap;
    align-content: flex-start;
    padding-top: 8px;
  }
}

@media only screen and (max-width: 900px) {
  .gridMapSite {
    padding-left: 10px;
  }

  .imageCnv {
    width: 110px;
  }

  .imageRenaper {
    width: 140px;
  }

  .imageCid {
    width: 70px;
  }

  .imagePdp {
    width: 90px;
  }

  .imageBcra {
    width: 80px;
  }

  .imageDataFiscal {
    width: 60px;
  }

  .imageUserInformation {
    width: 130px;
  }
}