.boxCalculator {
  background-image: url("../images/banners/banner_calculadoraWt.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 100%;
  background-size: cover;
  height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.gridSlider {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center !important;
}

.boxSlider {
  text-align: center !important;
}

.contentBoxSlider {
  padding-bottom: 15px;
}

.resultsCalculator {
  background-color:white;
  width: 450px;
  height: 480px;
  border-radius: 30px;
  direction: column;
  align-items: center;
  justify-content: center;
  margin: auto;
}

.boxResultsCalculator {
  padding-top: 25px;
  width: 80%;
  margin: auto;
}

.lineResults {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

hr {
  color: black;
  background-color: black;
  height: 2;
  margin: auto;
}

.buttonCredit {
  margin-top: 20px !important;
  font-weight: 500 !important;
  width: 304px;
}

.h2Amount {
  font-weight: 900;
  font-size: 200%;
}

.h1Calculate {
  font-size: 300% !important;
  width: 100%;
}

.marginCalculate {
 margin-bottom: 20px;
}

.pCalculator {
  font-size: 120%;
}

.resultMargin {
  padding-left: 20px;
}

.alignLeft {
  text-align: left;
}

.marginBottom {
  margin-bottom: 30px;
}

@media only screen and (min-width: 900px) and (max-width: 1200px)  {
  .resultsCalculator {
    margin-top: 20px;
  }

  .boxCalculator {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    height: 600px;
  }

}

@media only screen and (max-width: 900px) {
  .gridSlider {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    text-align: center !important;
  }

  .resultsCalculator {
    width: 100%;
    height: 375px;
    margin-top: 20px;
    border-style: groove;
  }

  .boxResultsCalculator {
    padding-top: 15px;
    width: 80%;
  }

  .h1Calculate {
    font-size: 200% !important;
    margin-top: 10px;
    width: 100%;
  }

  .contentBoxSlider {
    padding-bottom:7px;
  }

  .pCalculator {
    font-size: 90%;
    text-align: left;
  }

  .pAlign {
    text-align: center !important;
  }

  .h2Amount {
    font-weight: 900;
    font-size: 150%;
    text-align: center;
  }

  h2 {
    font-size: 70%;
    text-align: left;
  }

  .resultMargin {
    margin-left: 10px;
  }

  .buttonCredit {
    margin-top: 12px !important;
    width: 230px;
  }

  .pLegal {
    font-size: 65%;
  }
}