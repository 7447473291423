.onboarding-card-container {
  border: 1px solid #DA0067;
  background-color: #FBE6E9;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 30px;
  background: url('../images/hubRenewers/onboarding-card-background.svg'), #FBE6E9;
  background-repeat: no-repeat;
  background-position: right;
  background-size: 300px;
}

.onboarding-card-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 730px;
}

.onboarding-card-title {
  font-family: 'Poppins' !important;
  font-weight: 700 !important;
  font-size: 45px !important;
  line-height: 67.5px !important;
  color: #383838;
  text-align: left;
}

.onboarding-card-description {
  font-family: 'Open Sans' !important;
  font-weight: 400 !important;
  font-size: 30px !important;
  line-height: 40.85px !important;
  color: #383838;
  text-align: left;
}

.onboarding-card-cta {
  background-color: #DA0067 !important;
  border-radius: 35px !important;
  margin: 0;
  min-width: 300px !important;
  color: #FFFFFF;
  text-transform: capitalize !important;
  font-family: 'Open Sans' !important; 
  font-size: 14px;
  font-weight: 700 !important;
}

.onboarding-card-img {
  max-width: 200px;
}

@media only screen and (max-width: 1024px) {
  .onboarding-card-container {
    background: #FBE6E9;
    padding: 20px 10px;
    justify-content: flex-start;
    min-height: 130px;
  }

  .onboarding-card-title {
    font-size: 16px !important;
    line-height: 20px !important;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .onboarding-card-description {
    margin-top: 5px !important;
    font-size: 14px !important;
    line-height: 19.07px !important;
  }
}