.bannerRecargaCelular{
    background-color: #f2f2f2;
    width: 100%;
    text-align: center;
    height: 500px;
    padding: 4% 0;
}

.serviceTitle{
    font-family: sans-serif;
    padding-bottom: 2%;
    font-weight: 800;
    color: #383838;
    width: 100%;
    text-align: left;
}

.serviceSubtitle{
    font-weight: 600 !important;
    padding-bottom: 3%;
    font-size: 1.1rem;
    color: #383838;
    text-align: left;
}

.bannerText{
    margin-top: 70px;
    display: flex;
    flex-wrap: wrap;
    justify-content: left;
    padding-left: 8%;
}

.imgBannerRecargaCelular{
    width: 360px;
}

.boxButton{
    text-align: left;
    padding-left: 8%;
}

.ButtonMagentaRecarga{
    font-family: sans-serif;
    align-items: left;
    opacity: 1;
    border-radius: 35px !important;
    height: 3rem;
    line-height: 4.6rem;
    text-align: left;
    font-weight: 600;
    cursor: pointer;
    box-shadow: rgb(0 0 0 / 57%) 0px 5px 40px -10px;
    background-color: rgb(242, 2, 93) !important;
    color: rgb(255, 255, 255);
    border: none;
    width: 50%;
}

.buttonSimulate {
    width: 420px;
}

.ButtonMagentaRecarga:hover{
    background-color: white !important;
    color:  rgb(242, 2, 93);
    border: solid;
    border-color:  rgb(242, 2, 93);
}

.serviceBoldText{
    font-weight: 700;
}

.subtitleStepService{
    width: 50%;
}

.stepSubtitle{
    font-weight: bold;
    font-size: 14pt;
    padding-top: 1%;
}

.textIconsService{
    width: 100%;
    font-weight: 400;
}

.boxRecargaCelular{
    width: 460px;
    height: 300px;
    text-align: center;
    margin-top: 1rem;
    padding: 4rem 3rem 1rem;
}

.boxStepService{
    padding-top: 2%;
    width: 60%;
    text-align: center;
    margin: 0 auto;
}

.titleStepService{
    padding-bottom: 2%;
}

.textContrato{
    padding-top: 20px;
    font-size: 11px;
}

.boxCenter {
    display: flex;
    text-align: center;
    justify-content: center;
    flex-direction: column;
}

@media only screen and (max-width: 900px){
    .bannerRecargaCelular{
        height: 650px;
    }

    .serviceTitle{
        font-size: 1.5rem;
        width: 100%;
        text-align: center;
        margin-top: 11% !important;
    }

    .serviceSubtitle{
        text-align: center;
        margin: 0 auto;
    }

    .bannerText{
        margin-top: 5px;
        text-align: center;
        padding-left: 0;
    }

    .boxButton{
        padding: 5% 0;
        text-align: center;
    }

    .ButtonMagentaRecarga{
        width: 70%;
    }

    .imgBannerRecargaCelular{
        width: 200px;
    }

    .boxStepService{
        padding-top: 5%;
        width: 90%;
    }

    .boxStepService h2{
        font-size: 1.5rem;
    }

    .boxRecargaCelular{
        padding: 1rem 1rem 1rem;
        height: 200px;
    }

    .buttonSimulate {
        width: 200px;
    }

}